<template>
  <section class="container-scroller">

    <div class="row">
      <div class="col-lg-12 col-md-12 grid-margin">
        <div class="card">
          <div class="card-body">

            <h3 class="card-title text-white rounded p-3  text-center  "
              style="background-color: #0b5d3f; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)">
              Liste des Factures
            </h3>

            <router-link to="/payement/create" class="mt-2 py-2">
              <button type="submit" class="btn btn-success btn-medpay-green ">
                Facturer
              </button>
            </router-link>





            <!-- <div>
              <div class="d-flex justify-content-between align-items-center">
                <h3 class="card-title col-md-10 col-lg-10 text-white rounded p-3 mb-4 text-center" style=" 
                        background-color: #0B5D3F;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                      ">
                  Liste des Factures
                </h3>
                <router-link to="/payement/create" class="mt-n4">
                  <button type="submit" class="btn btn-success btn-medpay-green ">
                    Facturer
                  </button>
                </router-link>
              </div>
            </div> -->




            <div class="table-responsive py-1">
              <table id="order-listing" ref="myTable" class="table table-bordered">
                <thead>
                  <tr style="background-color: rgb(216, 218, 216)">
                    <th>#</th>
                    <th>Date</th>
                    <th>IEP</th>
                    <th>Référence</th>
                    <th>Mode</th>
                    <th>Patient</th>
                    <th>Montant</th>
                    <!-- <th>Status</th> -->
                    <th class="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(facture, index) in factures" :key="facture.id">
                    <td>{{ index + 1 }}</td>
                    <td> {{ formatDate(facture.created_at) }}</td>
                    <td>{{ facture.movments_id }}</td>
                    <td>{{ facture.reference }}</td>
                    <!-- <td>{{ facture.mode_payements_id }} </td> -->
                    <td>
                      <span v-if="facture.mode_payements_id == 1">ESPECE</span>
                      <span v-else-if="facture.mode_payements_id == 2">MTN MOBILE MONEY</span>
                      <span v-else-if="facture.mode_payements_id == 3">MOOV MONEY</span>
                      <span v-else-if="facture.mode_payements_id == 4">CELTIS</span>
                      <span v-else-if="facture.mode_payements_id == 5">CARTE BANCAIRE</span>
                      <span v-else-if="facture.mode_payements_id == 6">CARTE CREDIT</span>
                      <span v-else-if="facture.mode_payements_id == 7">TRESORPAY</span>
                    </td>
                    <td>{{ facture.firstname }} {{ facture.lastname }}</td>
                    <td>{{ facture.montant_total }}</td>

                    <td class="" width="50px">

                      <div class="btn-group btn-group-sm" role="group">
                        <button id="btnGroupDrop1" type="" style="padding: 0px 10px" class="btn btn-light dropdown-toggle"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          &nbsp;
                          <i class="fa fa-tasks" aria-hidden="true"></i> &nbsp;
                        </button>
                        <div class="dropdown-menu action-button-div" aria-labelledby="btnGroupDrop1"
                          style="box-shadow: 0 4px 6px rgb(0 0 0 / 30%)">
                          <!-- <button class="dropdown-item  font-weight-bold">
                            <i class="mdi mdi-file-check"></i> Facturer
                          </button> -->
                          <button @click="detail(facture, index)" class="dropdown-item text-info  font-weight-bold">
                            <i class="mdi mdi-eye"></i> Visualiser Facture
                          </button>

                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>




    <div class="modal fade" id="showDetail" tabindex="-1" role="dialog" aria-labelledby="showDetailLabel"
      aria-hidden="true">


      <div class="modal-dialog modal-dialog-centered  modal-xl" role="document">
        <div class="modal-content bg-white">

          <div class="modal-body" id="print-section" ref="modalContent" style="min-height: 100px !important;">

            <div class="container-fluid">
             
                <div class="row mb-2 mr-0 ml-0 col-sm-12">
                  <div class="col-sm-4"></div>
                  <div class="col-sm-12 d-flex justify-content-between">
                    <img src="../../../assets/images/santee.png" alt="logo" class=""
                      style="width: 180px !important; height: 50px !important; order: 2;" />
                    <img src="../../../assets/images/logo.png" alt="logo" class=""
                      style="width: 130px !important; height: 50px !important; order: 1;" />
                  </div>
                </div>
             

              <hr style="background-color: rgb(156, 151, 151);" class="mt-n2 ">


              <div class="row mb-1">

                <div class="col-sm-6">
                  <h4><strong> PARTIE VERSANTE</strong></h4>
                  <p><strong> {{ patient.lastname }} {{ patient.firstname }}</strong></p>

                  <p>{{ patient.maison }}</p>
                  <p>{{ patient.phone }}</p>
                  <p><strong>IPP :</strong> {{ patient.ipp }} | <strong> IEP : {{ patient.iep }} </strong></p>

                </div>
                <div class="col-sm-6 text-right">
                  <h4><strong> N° {{ reference }}</strong></h4>
                  <p> <strong>Date : {{ formatDate(date) }}</strong></p>
                  <p><strong>LOKOSSA ATHIEME</strong> </p>
                  <p>Code postal 09 6 51 03</p>
                  <p>En allant à LINK Hotel</p>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Mode</th>
                      <th>code</th>
                      <th>Désignation</th>
                      <th>Quantité</th>
                      <th>Prix unitaire</th>
                      <th>Montant</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr v-for="(factureDetail, index) in factureDetails" :key="index">
                      <td>
                        <span v-if="factureDetail.mode_payements_id == 1">ESPECE</span>
                        <span v-else-if="factureDetail.mode_payements_id == 2">MOBILE MONEY</span>
                        <span v-else-if="factureDetail.mode_payements_id == 3">MOOV MONEY</span>
                        <span v-else-if="factureDetail.mode_payements_id == 4">CELTIS</span>
                        <span v-else-if="factureDetail.mode_payements_id == 5">CARTE BANCAIRE</span>
                        <span v-else-if="factureDetail.mode_payements_id == 6">CARTE DE CRÉDIT</span>
                        <span v-else-if="factureDetail.mode_payements_id == 7">PAIEMENT TRESORPAY</span>
                        <span v-else-if="factureDetail.mode_payements_id == 8">PAIEMENT A CREDIT</span>
                      </td>
                      <td>
                        <span v-if="factureDetail.code === null">AAAAA</span>
                        <span v-else> {{ factureDetail.code }}</span>
                      </td>

                      <td>
                        {{ factureDetail.designation }}
                      </td>
                      <td>{{ factureDetail.quantite }}</td>
                      <td>{{ factureDetail.prix }}</td>
                      <td>{{ factureDetail.prix * factureDetail.quantite }}</td>
                    </tr>

                  </tbody>
                  <tfoot>
                    <tr>

                      <td colspan="5" class="text-right"><strong>Montant Total (+) </strong></td>
                      <td><strong>{{ formatNumber(calculerMontantTotal()) }} FCFA</strong></td>
                    </tr>
                    <tr>

                      <td colspan="5" class="text-right"><strong> Total Prise en charge (-)</strong></td>
                      <td><strong>{{ formatNumber(this.assurancePourcentage) }} FCFA</strong></td>
                    </tr>

                    <!-- <tr v-if="mode_payements_id == 3">

                      <td colspan="5" class="text-right"><strong> Fedapay fees (+)</strong></td>

                      <td><strong>{{ formatNumber((montantPaye - (calculerMontantTotal() - 0))) }}
                          FCFA</strong></td>
                    </tr>


                    <tr v-if="mode_payements_id == 2">

                      <td colspan="5" class="text-right"><strong> Kkiapay fees (+)</strong></td>

                      <td><strong>{{ formatNumber((montantPaye - (calculerMontantTotal() - 0))) }}
                          FCFA</strong></td>
                    </tr> -->

                    <tr>
                      <td colspan="5" class="text-right" style="background-color: rgb(213, 219, 218);">
                        <strong>Montant Total Versé</strong>
                      </td>
                      <td style="background-color: rgb(213, 219, 218)"><strong>{{ formatNumber(calculerMontantTotal()-this.assurancePourcentage) }}
                          FCFA</strong></td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div class="row mt-1">
                <div class="col-sm-6">

                  <p>Arrête la présente facture à la somme de : <strong>{{
                    convertNumberToLetter(formatNumber(calculerMontantTotal()-this.assurancePourcentage)) }} FCFA </strong></p>

                  <div>
                    <router-link v-if="qrCodeUrl" :to="{
                      name: 'FactureDetails',
                      params: { reference: reference },
                      path: '/facture' + $route.path,
                    }" target="_blank">
                      <img :src="qrCodeUrl" alt="QR Code" style="height:60px" />
                    </router-link>
                  </div>


                </div>
                <div class="col-sm-6 text-right">
                  <p><strong> Le Chef Caissier </strong></p>
                  <img src="@/assets/images/signature.png" style="width:80px">
                  <p><strong> Félicien DAGBOGBO </strong></p>


                </div>
              </div>
            </div>

          </div>


          <div class="modal-footer d-flex justify-content-between col-12 flex-row">


            <button type="button" class="btn btn-danger btn-medpay-gray" data-dismiss="modal">
              Annuler
            </button>

            <button type="button" class="btn btn-success btn-medpay-green" @click="printModal">
              Imprimer
            </button>

          </div>
        </div>
      </div>
    </div>



  </section>
</template>
<script>


import $ from "jquery";

import * as JSZip from "../../../../node_modules/jszip";
window.JSZip = JSZip;
import pdfMake from "../../../../node_modules/pdfmake/build/pdfmake";
import vfsFonts from "../../../../node_modules/pdfmake/build/vfs_fonts";
pdfMake.vfs = vfsFonts.pdfMake.vfs;
import "../../../../node_modules/datatables.net-dt";
import "../../../../node_modules/datatables.net-bs4/js/dataTables.bootstrap4";
import "../../../../node_modules/datatables.net-buttons/js/dataTables.buttons";
import "../../../../node_modules/datatables.net-buttons-bs4/js/buttons.bootstrap4";
import FrenchTranslation from "@/assets/datatable/French.json";
import "../../../../node_modules/datatables.net-buttons/js/buttons.html5.js";
import "../../../../node_modules/datatables.net-buttons/js/buttons.print.js";
import "../../../../node_modules/datatables.net-buttons/js/buttons.colVis.js";
import "../../../../node_modules/bootstrap/dist/js/bootstrap.js"; // tres important pour le modal
import "../../../../node_modules/bootstrap/dist/js/bootstrap.min.js"; // tres important pour le modal
import { AssuranceService } from "@/_services/assurances_services.js";
import { compagnieService } from "@/_services/compagnie_services.js";
import { factureService } from "@/_services/facture_services.js";
import { patientService } from "@/_services";
import { actsService } from '@/_services';
import { pourcentagePatient } from "@/_services/pourcentage_indigence_patient_services.js";
import { PriseEnChargePatientService } from "@/_services/priseenchargepatient_services.js";
import QRCode from 'qrcode';

import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';


require("datatables.net-dt");

export default {
  name: "list-facture",

  data() {
    return {
      factures: [],
      table: null,
      factureDetails: [],
      nom: "",
      patientId: "",
      patient: {},
      patients: [],
      pourcentage: "",
      id: "",
      assurance: [],
      date: "",
      nom: "",
      prenom: "",
      nomCentre: "",
      code: "",
      referenceFacture: null,
      autre: "",
      prix: "",
      quantite: "",
      montant: "",
      mode_payement: "",
      mode_payements_id: "",
      total_montant: "",
      payement_id: "",
      nomActeResult: "",
      nomsActes: [],
      acts: [],
      assurancePourcentage: null,
      pourcentageIndigence: null,
      totalPriseEncharge: "",
      difference: "",
      qrCodeUrl: '',
      fees: "",
      reference: '',
      montantPaye: '',

    };
  },

  mounted() {



    // patientService
    //   .getAllPatients()
    //   .then((res) => {
    //     this.patients = res.data.data;
    //     // console.log(this.patients);
    //   })



    // actsService.getAllActes().then((res) => {
    //   this.acts = res.data.data;
    // });


    factureService.getAllFatures().then((res) => {
      this.factures = res.data.data;

      console.log(res.data.user);

      this.$nextTick(() => {
        const table = $(this.$refs.myTable).DataTable({
          // dom: '<"html5buttons"B>lTfgtip',
          dom:
            '<"row mb-3"<"col-md-12"B>>' +
            '<"row mb-0"<"col-md-6"l><"col-md-6"f>>' +
            '<"row"<"col-md-12"tr>>' +
            '<"row"<"col-md-6"i><"col-md-6"p>>',
          //  dom: 'Bfrtip',
          //  dom: 'lBfrtip',

          pageLength: 10, // Définir le nombre de résultats par page
          language: FrenchTranslation,
          buttons: [
            // {
            //   extend: "csvHtml5", charset: 'utf-8', bom: true, className: 'btn btn-gray btn-sm',// Extend the excel button
            // },
            // {
            //   extend: "excelHtml5", charset: 'utf-8', bom: true, className: 'btn btn-gray btn-sm',
            // },

            // {
            //   extend: "pdfHtml5", charset: 'utf-8', bom: true, className: 'btn btn-gray btn-sm',
            //   // className: 'btn btn-primary',
            // },

            // { extend: "print", charset: 'utf-8', bom: true, className: 'btn btn-gray btn-sm', },
            // { extend: "copy", charset: 'utf-8', bom: true, className: 'btn btn-gray btn-sm', },
          ],
        });

        table
          .buttons()
          .container()
          .prependTo("#myTable_wrapper .col-md-6:eq(0)");
      });
    });


  },

  methods: {




    printModal() {
  // Ouvrez le modal
  $("#showDetail").modal("show");

  // Assurez-vous que la fenêtre de prévisualisation ne s'ouvre qu'une fois
  if (typeof this.printWindow === 'undefined' || this.printWindow.closed) {
    // Sélectionnez l'élément que vous voulez imprimer (la partie spécifique avec l'ID "print-section")
    const printContent = document.getElementById("print-section");

    // Créez une fenêtre de prévisualisation pour l'impression
    this.printWindow = window.open('', '', 'width=600,height=600');

    // Attendez un court délai pour que le modal soit entièrement affiché
    setTimeout(function() {
      // Insérez le contenu HTML de la page d'origine dans la fenêtre de prévisualisation
      this.printWindow.document.write('<html><head><title>Impression</title>');

      // Incluez les balises <link> pour les fichiers Bootstrap CDN
      this.printWindow.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">');
      this.printWindow.document.write('<link rel="stylesheet" href="../../../../node_modules/bootstrap-vue/dist/bootstrap-vue.css">');

      // Incluez une balise <link> pour votre fichier CSS externe
      this.printWindow.document.write('<link rel="stylesheet" type="text/css" href="../../../../assets/scss/style.css">');

      // Ajoutez des styles CSS personnalisés pour les en-têtes de tableau
      this.printWindow.document.write('<style>.table th { background-color: rgb(213, 219, 218) !important; padding: 5px; }</style>');

      // Ajoutez des styles CSS pour réduire les marges internes (padding) des cellules du tableau
      this.printWindow.document.write('<style>.table th td { padding: 2px; }</style>');

      this.printWindow.document.write('</head><body>');
      this.printWindow.document.write(printContent.innerHTML);
      this.printWindow.document.write('</body></html>');
      this.printWindow.document.close();

      // Attendez que le contenu soit chargé avant d'imprimer
      this.printWindow.onload = function () {
        this.printWindow.print();
        this.printWindow.close();
      }.bind(this);

      // Fermez le modal
      // $("#showDetail").modal("hide");
    }.bind(this), 1000); // Ajustez la durée en fonction de votre besoin
  }
},








    detail(facture, index) {

      let assuranceMontant = 0;
      let indigenceMontant = 0;



      // this.payement_id = facture.reference;
      // this.mode_payements_id = facture.mode_payements_id;
      // this.patientId = facture.patient_id

      this.reference = facture.reference;

      factureService.getFacture(this.reference)
        .then((res) => {
          const factureDetails = res.data.data;

          console.log(factureDetails);

          this.factureDetails = factureDetails;
          this.nom = this.factureDetails[0].firstname;
          this.prenom = this.factureDetails[0].lastname;
          // this.telpa = this.factureDetails[0].lastname;
          // this.nomCentre = this.factureDetails[0].nom_centre;
          this.nomCentre = "Lokossa";

          this.mode_payements_id = facture.mode_payements_id;

          // this.montantPaye = facture.total_montant;
          this.date = facture.created_at;
          // this.generateQRCode();
          this.generateQRCode(this.reference)
          const montantTotalActe = this.calculerMontantTotal();


          this.montantPaye = montantTotalActe;

          this.assurancePourcentage = (this.factureDetails[0].percentageassurance * this.montantPaye) / 100;
          


          factureService
            .getPatientInfo(this.factureDetails[0].movments_id)
            .then((res) => {
              this.patient = res.data.data;

              // console.log(" this.patientSelected");
              // console.log(this.patient);
            })
            .catch((error) => {
              console.error(error);
            });

          $("#showDetail").modal("show");

          // this.difference = facture.total_montant;
          // PriseEnChargePatientService.getAssurancePatient(facture.patient_id)
          //   .then((res) => {
          //     this.assurancePourcentage = res.data.data.pourcentage_assurance;
          //     if (this.assurancePourcentage) {
          //       assuranceMontant = (this.assurancePourcentage * montantTotalActe) / 100;
          //     } else {
          //       assuranceMontant = 0;
          //     }

          //     pourcentagePatient.getPourcentagePatient(facture.patient_id)
          //       .then((res) => {
          //         this.pourcentageIndigence = res.data.data;
          //         if (this.pourcentageIndigence) {
          //           indigenceMontant = (this.pourcentageIndigence * montantTotalActe) / 100;
          //         } else {
          //           indigenceMontant = 0;
          //         }

          //         this.totalPriseEncharge = assuranceMontant + indigenceMontant;
          //         // this.difference = montantTotalActe - this.totalPriseEncharge;
          //         this.difference = facture.total_montant;

          //         console.log(this.difference);

          //         $("#showDetail").modal("show");
          //       })
          //       .catch(error => {
          //         console.error(error);
          //       });
          //   })
          //   .catch(error => {
          //     console.error(error);
          //   });
        })
        .catch(error => {
          console.error(error);
        });
    },

    // generateQRCode(reference) {

    //   const invoiceDetailsUrl = `/facture/details/${reference}`;

    //   QRCode.toDataURL(invoiceDetailsUrl, { errorCorrectionLevel: "L" }, (err, url) => {
    //     if (err) {
    //       console.error(err);
    //       return;
    //     }
    //     this.qrCodeUrl = url;
    //   });
    // },

    generateQRCode(reference) {
      const base = location.origin;
      reference = this.reference;
      const invoiceDetailsUrl = `${base}/facture/details/${this.reference}`;

      QRCode.toDataURL(invoiceDetailsUrl, { errorCorrectionLevel: "L" }, (err, url) => {
        if (err) {
          console.error(err);
          return;
        }

        this.qrCodeUrl = url; //  genere le code QR
        console.log("this.qrCodeUrl")
        console.log(this.qrCodeUrl)
      });
    },






    calculerMontantTotal() {
      let montantTotal = 0;
      for (const factureDetail of this.factureDetails) {
        montantTotal += factureDetail.prix * factureDetail.quantite;
      }
      return montantTotal;
    },

    getNomActe(idActe) {
      const acte = this.acts.find((acte) => acte.id === idActe);
      return acte ? acte.name : "Autre";
    },

    getPatientTelephone() {
      const patient = this.patients.find((patient) => this.patientId === patient.id);
      return patient ? patient.telephone : "";
    },

    getPatientAdresse() {
      const patient = this.patients.find((patient) => this.patientId === patient.id);
      return patient ? patient.adresse : "";
    },



    // formatDate(date) {
    //   const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    //   return new Date(date).toLocaleDateString('fr-FR', options);
    // },

    formatDate(date) {
      const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' };
      return new Date(date).toLocaleString('fr-FR', options);
    },




    formatNumber(value) {
      if (typeof value !== 'number') {
        return value;
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },


    Unite(nombre) {
      let unite;
      switch (nombre) {
        case 0:
          unite = "zéro";
          break;
        case 1:
          unite = "un";
          break;
        case 2:
          unite = "deux";
          break;
        case 3:
          unite = "trois";
          break;
        case 4:
          unite = "quatre";
          break;
        case 5:
          unite = "cinq";
          break;
        case 6:
          unite = "six";
          break;
        case 7:
          unite = "sept";
          break;
        case 8:
          unite = "huit";
          break;
        case 9:
          unite = "neuf";
          break;
      }
      return unite;
    },

    Dizaine(nombre) {
      let dizaine;
      switch (nombre) {
        case 10:
          dizaine = "dix";
          break;
        case 11:
          dizaine = "onze";
          break;
        case 12:
          dizaine = "douze";
          break;
        case 13:
          dizaine = "treize";
          break;
        case 14:
          dizaine = "quatorze";
          break;
        case 15:
          dizaine = "quinze";
          break;
        case 16:
          dizaine = "seize";
          break;
        case 17:
          dizaine = "dix-sept";
          break;
        case 18:
          dizaine = "dix-huit";
          break;
        case 19:
          dizaine = "dix-neuf";
          break;
        case 20:
          dizaine = "vingt";
          break;
        case 30:
          dizaine = "trente";
          break;
        case 40:
          dizaine = "quarante";
          break;
        case 50:
          dizaine = "cinquante";
          break;
        case 60:
          dizaine = "soixante";
          break;
        case 70:
          dizaine = "soixante-dix";
          break;
        case 80:
          dizaine = "quatre-vingt";
          break;
        case 90:
          dizaine = "quatre-vingt-dix";
          break;
      }
      return dizaine;
    },

    NumberToLetter(nombre) {
      let i, j, n, quotient, reste, nb;
      let ch;
      let numberToLetter = "";

      if (nombre.toString().replace(/ /gi, "").length > 15) return "dépassement de capacité";
      if (isNaN(nombre.toString().replace(/ /gi, ""))) return "Nombre non valide";

      nb = parseFloat(nombre.toString().replace(/ /gi, ""));
      if (Math.ceil(nb) !== nb) return "Nombre avec virgule non géré.";

      n = nb.toString().length;
      switch (n) {
        case 1:
          numberToLetter = this.Unite(nb);
          break;
        case 2:
          if (nb > 19) {
            quotient = Math.floor(nb / 10);
            reste = nb % 10;
            if (nb < 71 || (nb > 79 && nb < 91)) {
              if (reste === 0) numberToLetter = this.Dizaine(quotient * 10);
              if (reste === 1) numberToLetter = this.Dizaine(quotient * 10) + "-et-" + this.Unite(reste);
              if (reste > 1) numberToLetter = this.Dizaine(quotient * 10) + "-" + this.Unite(reste);
            } else numberToLetter = this.Dizaine((quotient - 1) * 10) + "-" + this.Dizaine(10 + reste);
          } else numberToLetter = this.Dizaine(nb);
          break;
        case 3:
          quotient = Math.floor(nb / 100);
          reste = nb % 100;
          if (quotient === 1 && reste === 0) numberToLetter = "cent";
          if (quotient === 1 && reste !== 0) numberToLetter = "cent" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste === 0) numberToLetter = this.Unite(quotient) + " cents";
          if (quotient > 1 && reste !== 0) numberToLetter = this.Unite(quotient) + " cent " + this.NumberToLetter(reste);
          break;
        case 4:
        case 5:
        case 6:
          quotient = Math.floor(nb / 1000);
          reste = nb - quotient * 1000;
          if (quotient === 1 && reste === 0) numberToLetter = "mille";
          if (quotient === 1 && reste !== 0) numberToLetter = "mille" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " mille";
          if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " mille " + this.NumberToLetter(reste);
          break;
        case 7:
        case 8:
        case 9:
          quotient = Math.floor(nb / 1000000);
          reste = nb % 1000000;
          if (quotient === 1 && reste === 0) numberToLetter = "un million";
          if (quotient === 1 && reste !== 0) numberToLetter = "un million" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " millions";
          if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " millions " + this.NumberToLetter(reste);
          break;
        case 10:
        case 11:
        case 12:
          quotient = Math.floor(nb / 1000000000);
          reste = nb - quotient * 1000000000;
          if (quotient === 1 && reste === 0) numberToLetter = "un milliard";
          if (quotient === 1 && reste !== 0) numberToLetter = "un milliard" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " milliards";
          if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " milliards " + this.NumberToLetter(reste);
          break;
        case 13:
        case 14:
        case 15:
          quotient = Math.floor(nb / 1000000000000);
          reste = nb - quotient * 1000000000000;
          if (quotient === 1 && reste === 0) numberToLetter = "un billion";
          if (quotient === 1 && reste !== 0) numberToLetter = "un billion" + " " + this.NumberToLetter(reste);
          if (quotient > 1 && reste === 0) numberToLetter = this.NumberToLetter(quotient) + " billions";
          if (quotient > 1 && reste !== 0) numberToLetter = this.NumberToLetter(quotient) + " billions " + this.NumberToLetter(reste);
          break;
      }

      // Respect de l'accord de "quatre-vingt"
      if (numberToLetter.substr(numberToLetter.length - "quatre-vingt".length, "quatre-vingt".length) === "quatre-vingt") {
        numberToLetter += "s";
      }

      return numberToLetter;
    },

    convertNumberToLetter(number) {
      let result = this.NumberToLetter(number);
      return result;
    },


    // printModal() {
    //   const modalContent = this.$refs.modalContent;
    //   const originalContents = document.body.innerHTML;

    //   // Temporairement remplacer le contenu de la page par le contenu du modal
    //   document.body.innerHTML = modalContent.innerHTML;

    //   // Imprimer le contenu
    //   window.print();


    //   // Restaurer le contenu original de la page
    //   document.body.innerHTML = originalContents;

    //   $("#exampleModal").modal("hide");
    // },







    //     printModal() {
    //   const modalContent = this.$refs.modalContent;

    //   const pdfOptions = {
    //     margin: 10, // Marge en pixels
    //     filename: 'modal.pdf', // Nom du fichier PDF
    //     html2canvas: { scale: 2 },
    //     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    //   };

    //   const pdfPromise = html2pdf().from(modalContent).set(pdfOptions).outputPdf();

    //   pdfPromise.then(pdf => {
    //     const blob = new Blob([pdf], { type: 'application/pdf' });
    //     const objectURL = URL.createObjectURL(blob);
    //     window.open(objectURL, '_blank');
    //   });
    // },


    // printModal() {
    //   const modalContent = this.$refs.modalContent;

    //   // Créez une nouvelle fenêtre d'impression
    //   const printWindow = window.open('', '', 'width=800,height=600');

    //   // Injectez les styles CSS d'impression dans la fenêtre d'impression
    //   const styles = '<style>@media print{}</style>';
    //   printWindow.document.write(styles);

    //   // Injectez le contenu de votre modal dans la fenêtre d'impression
    //   printWindow.document.write('<html><head><title>PDF Export</title></head><body>');
    //   printWindow.document.write(modalContent.innerHTML);
    //   printWindow.document.write('</body></html>');
    //   printWindow.document.close();

    //   // Sélectionnez l'imprimante "Microsoft Print to PDF" si nécessaire
    //   // (vous pouvez omettre cette étape si vous ne souhaitez pas spécifier l'imprimante par défaut)
    //   printWindow.addEventListener('load', () => {
    //     if (printWindow.document.execCommand) {
    //       printWindow.document.execCommand('print', false, 'Microsoft Print to PDF');
    //     }
    //   });
    // }











  }

};
</script>

<style scoped>
@import "../../../../node_modules/bootstrap-vue/dist/bootstrap-vue.css";
/* @import '../../../../node_modules/datatables.net-dt/css/jquery.dataTables.css'; */

@import "../../../../node_modules/datatables.net-bs4/css/dataTables.bootstrap4.css";



/* Styles pour la page d'impression */

/* Styles spécifiques pour l'écran (non imprimé) */
@media screen {
  /* Vous pouvez définir ici les styles pour l'affichage à l'écran */
}






table th, td {
  text-align:left;
}



.table th {
  background-color: #d6dbd6 !important;
  border: 1px solid #dee2e6;
}

.col-sm-6 p {
  margin-bottom: 2px !important;
}

table td th {
  border-top: 2px solid #ffffff;
}

th,
td {
  text-align: left;
}


.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered td {
  border: 1px solid #b6b9bb;
}


th {
  background-color: #caceca;
  border: 1px solid #b6b9bb;
}


select {
  cursor: pointer;
  height: 43px !important;
}
</style>
